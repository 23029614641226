import { Map } from "immutable";
import Actions from "./actions";

function handleSetSelectObject(state, { proposition }) {
  return state.setIn(["selected"], Map(proposition));
}

export default function(state = Map(), action) {
  switch (action.type) {
    case Actions.SET_SELECTED_OBJECT:
      return handleSetSelectObject(state, action);
    default:
      return state;
  }
}
