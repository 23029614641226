import PropTypes from "prop-types";
import React, { useState, Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSelectedObject } from "../object/actions";
import Header from "./Header";

import {
  imageKitPreviewUrl,
  toCurrency,
  renderDayPrice,
  renderMonthPrice,
} from "../utils";
import { withNamespaces } from "react-i18next";
import { localizeBlind } from "../../assets/i18n/i18n";

@connect(
  () => ({}),
  (dispatch) => bindActionCreators({ push, setSelectedObject }, dispatch),
)
class OutputItem extends React.Component {
  render() {
    const { object, t, currency, toggleCurrency } = this.props;

    return (
      <li className="output__list-item">
        <a
          className="output__item"
          onClick={() => {
            this.props.setSelectedObject(object);
            this.props.push(`/object/${object.publicId}`);
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            window.open(window.location.origin + `/object/${object.publicId}`);
          }}
        >
          <div className="object__image">
            <img src={imageKitPreviewUrl(object.publicId, object.mainImage)} />
          </div>
          <div className="object__info">
            <div className="object__name-label">
              <p className="object__name">
                <span>{t("itemBeach")}</span> - {localizeBlind(object.beach)}
              </p>
              <p className="object__label">{localizeBlind(object.type)}</p>
            </div>
            <p className="object__name-id">{object.publicId}</p>
            <div className="object__important">
              <p className="object__important-item">
                {object.sleepingRoomCount}
              </p>
              <p className="object__important-item">
                {object.washroomRoomCount}
              </p>
              <p className="object__important-item">
                {object.swimmingPool ? t("itemYes") : t("itemNo")}
              </p>
              <p className="object__important-item">
                {object.distanceToSea} {t("itemMeter")}
              </p>
            </div>
            <div className="object__prices">
              <p className="object__price">
                <i>{t("itemDay")}</i>
                <span>{renderDayPrice(object.dayPrice, currency)}</span>
                <i>{t("itemMonth")}</i>
                <span>{renderMonthPrice(object.monthPrice, currency)}</span>
              </p>
            </div>

            {object.monthPrice && !object.dayPrice && (
              <p className="object__price">
                <i>{t("itemMonth")}</i>
                <span>
                  ฿{" "}
                  {toCurrency(object.monthPrice, "THB").toLocaleString(
                    "th-TH",
                    {
                      style: "decimal",
                    },
                  )}
                </span>
                <span>
                  ${" "}
                  {toCurrency(object.monthPrice, "USD").toLocaleString(
                    "en-US",
                    {
                      style: "decimal",
                    },
                  )}
                </span>
              </p>
            )}
            {!object.monthPrice && object.dayPrice && (
              <p className="object__price">
                <i>{t("itemDay")}</i>
                <span>
                  ฿{" "}
                  {toCurrency(object.dayPrice, "THB").toLocaleString("th-TH", {
                    style: "decimal",
                  })}
                </span>
                <span>
                  ${" "}
                  {toCurrency(object.dayPrice, "USD").toLocaleString("en-US", {
                    style: "decimal",
                  })}
                </span>
              </p>
            )}
          </div>
        </a>
      </li>
    );
  }
}

export default withNamespaces("results")(OutputItem);

OutputItem.propTypes = {
  object: PropTypes.object.isRequired,
  push: PropTypes.func,
  setSelectedObject: PropTypes.func,
  currency: PropTypes.string.isRequired,
  toggleCurrency: PropTypes.func.isRequired,
};
