import PropTypes from "prop-types";
import React from "react";
import { setSearchDistanceToSea } from "../actions";
// import { getCurrentLocale, localize } from "../../../assets/i18n/i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

@connect(
  (state) => ({
    distanceToSea: state.getIn(["app", "search", "query", "distanceToSea"]),
  }),
  (dispatch) => bindActionCreators({ setSearchDistanceToSea }, dispatch),
)
class DistanceToSeaFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distances: [
        { id: 0, min: 0, max: 100, name: "0m" },
        { id: 1, min: 100, max: 500, name: "100m" },
        { id: 2, min: 1000, max: 1500, name: "500m" },
        { id: 3, min: 1500, name: "1500m" },
      ],
    };
  }
  renderChosen() {
    const { distanceToSea, setSearchDistanceToSea, t } = this.props;

    if (!!distanceToSea) {
      return (
        <div className="filter__dropdown chosen">
          <div className="filter__option">
            <label className="filter__label checked">
              <input
                className="checkbox"
                type="checkbox"
                onChange={() => setSearchDistanceToSea(null)}
              />
              {t(distanceToSea.name)}
            </label>
          </div>
        </div>
      );
    }
  }
  render() {
    const { distanceToSea, setSearchDistanceToSea, t, open, showOrHide } =
      this.props;

    const { distances } = this.state;

    return (
      <div className={`filter__item distance_to_sea ${open ? "open" : ""}`}>
        <p className="filter__button button" onClick={() => showOrHide()}>
          {t("distanceToSeaFilter")}
          <i class="fa-solid fa-chevron-down"></i>
        </p>
        {this.renderChosen()}
        <div
          className="filter__dropdown"
          // style={open ? { display: "block" } : { display: "none" }}
        >
          <p
            className="filter__close distance_to_sea-close"
            onClick={() => showOrHide()}
          ></p>
          <div>
            {distances.map((item) => (
              <label
                key={item.id}
                id={item.id}
                className="filter__radio__label"
              >
                <input
                  type="checkbox"
                  className="filter__radio"
                  value={item.id}
                  checked={distanceToSea?.id === item.id}
                  onChange={(e) => {
                    if (e.target.value == distanceToSea?.id) {
                      setSearchDistanceToSea(null);
                      return;
                    }
                    for (const distance of distances) {
                      if (distance.id == Number(e.target.value)) {
                        setSearchDistanceToSea(distance);
                        break;
                      }
                    }
                  }}
                />
                <p className="filter__radio__label__text">{t(item.name)}</p>
              </label>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces("searchForm")(DistanceToSeaFilter);

DistanceToSeaFilter.propTypes = {
  setSearchDistanceToSea: PropTypes.func,
  distanceToSea: PropTypes.object,
  open: PropTypes.bool,
  showOrHide: PropTypes.func,
};
