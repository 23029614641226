import React from "react";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { toggleMenu } from "../utils";

@connect(() => ({}), (dispatch) => bindActionCreators({ push }, dispatch))
class Title extends React.Component {
  render() {
    const { isSearchHeader = false, t } = this.props;
    return (
      <div>
        <h1 class="filter-title">{t("title")}</h1>
        <h2 class="filter-sub-title">{t("titleFilters")}</h2>
      </div>
    );
  }
}

export default withNamespaces("title")(Title);

Title.propTypes = {
  push: PropTypes.func,
  isSearchHeader: PropTypes.bool,
};
