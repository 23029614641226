import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchSearchOptions } from '../search/actions'
import { List } from 'immutable'
import { withNamespaces } from 'react-i18next'
import { getCurrentLocale, localize } from '../../assets/i18n/i18n'
import { Map } from 'immutable'

@connect(
  (state) => ({
    selected: state.getIn(['app', 'object', 'selected']) || Map(),
    options: state.getIn(['app', 'search', 'options']) || List()
  }),
  (dispatch) => bindActionCreators({ fetchSearchOptions }, dispatch)
)
class OptionsSection extends React.Component {

  componentWillMount() {
    if (!this.props.options || this.props.options.size === 0) {
      this.props.fetchSearchOptions();
    }
  }

  render() {
    const { options, selected, t } = this.props;
    const objectOptions = selected.get('options') || List();
    const locale = getCurrentLocale();

    return (
      <section className='comfort'>
        <div className='wrapper'>
          <p className='title object__title'>
            {t('options')}
          </p>
          <div className='comfort__wrap'>
            {options.map((type) =>
              <p key={type.id} className={`comfort__item ${type.id} ${objectOptions[type.id] && objectOptions[type.id] === true ? '' : ' none'}`}>
                {localize(locale, type.name).replace(/conditioner/g, 'conditioner').replace(/washingMachine/g, 'washing machine').replace(/dishWasher/g, 'dishwasher').replace(/microwaveOven/g, 'microwave oven').replace(/hairDryer/g, 'hairdryer').replace(/teaPot/g, 'teapot').replace(/coffeePot/g, 'coffee maker').replace(/wifi/g, 'Wi-Fi').replace(/babyCot/g, 'baby cot').replace(/swimmingPool/g, 'swimming pool').replace(/closedArea/g, 'protected area').replace(/seaView/g, 'sea view').replace(/nearSea/g, 'near the sea').replace(/bedClothes/g, 'bed linen').replace(/refrigerator/g, 'fridge').replace(/kitchenWare/g, 'kitchen ware').replace(/tableWare/g, 'tableware')}
              </p>
            )}
          </div>

        </div>
      </section>
    )
  }
}

export default withNamespaces('proposition')(OptionsSection)

OptionsSection.propTypes = {
  options: PropTypes.object,
  selected: PropTypes.object,
  fetchSearchOptions: PropTypes.func
};




