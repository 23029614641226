import React from "react";
import PropTypes from "prop-types";
import { getCurrentLocale, localize } from "../../../assets/i18n/i18n";
import { checkAll, isChecked } from "../../utils";
import CheckAllButton from "./CheckAllButton";
import CloseButton from "./CloseButton";
import _ from "lodash";

export default class AbstractFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      toggleOne,
      toggleAll,
      selected,
      title,
      prefix,
      values,
      showChosen = false,
      open = false,
      sortByName = false,
      showOrHide,
    } = this.props;
    const locale = getCurrentLocale();

    return (
      <div className={`filter__item ${open ? "open" : ""}`}>
        <p className="filter__button button" onClick={() => showOrHide()}>
          {title} <i class="fa-solid fa-chevron-down"></i>
        </p>
        {renderChosen(showChosen, selected, toggleOne, locale)}
        <div id="rooms" className="filter__dropdown">
          <div className="filter__option">
            {(sortByName
              ? _.orderBy(values.toJS(), `name.${locale}`, "asc")
              : values
            ).map((item) => (
              <label
                key={item.id}
                id={prefix + item.id}
                className={"filter__label " + isChecked(selected, item)}
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  value={item.id}
                  onChange={(e) => toggleOne(item, e.target.checked)}
                />
                {localize(locale, item.name)}
              </label>
            ))}
          </div>
          <CheckAllButton
            onClick={() => checkAll(prefix, values.toJS(), toggleAll)}
          />
          <CloseButton onClick={() => showOrHide()} />
        </div>
      </div>
    );
  }
}

function renderChosen(showChosen, selected, toggleOne, locale) {
  if (showChosen) {
    return (
      <div className="filter__dropdown ">
        {selected.map((item, idx) => (
          <div key={idx} className="filter__option">
            <label className="filter__label checked">
              <input
                className="checkbox"
                type="checkbox"
                onChange={() => toggleOne(item, false)}
              />
              {localize(locale, item.name)}
            </label>
          </div>
        ))}
      </div>
    );
  }
}

AbstractFilter.propTypes = {
  showChosen: PropTypes.bool,
  toggleOne: PropTypes.func,
  toggleAll: PropTypes.func,
  selected: PropTypes.array,
  values: PropTypes.object,
  title: PropTypes.string,
  prefix: PropTypes.string,
  open: PropTypes.bool,
  showOrHide: PropTypes.func,
  sortByName: PropTypes.bool,
};
