import { call } from 'redux-saga/effects'
import { List, Map } from 'immutable'
import fetch from 'isomorphic-fetch'
import { notify } from '../utils/notification'
import { getCurrentLocale } from '../assets/i18n/i18n'

const request = (method, url, body) => {

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'locale': getCurrentLocale()
  };

  return fetch(url, {
    method,
    body: JSON.stringify(body),
    headers,
    credentials: 'same-origin'
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw {
        status: response.status,
        message: response.statusText
      };
    }
  }).then((json) => json);
};

export function* list(method, url, body) {
  let result = {};
  try {
    const response = yield call(request, method, url, body);
    result.data = List(response);
  } catch (error) {
    result.error = error;
    yield call(showError);
  }

  return result;
}

export function* one(method, url, body) {
  let result = {};
  try {
    const response = yield call(request, method, url, body);
    result.data = Map(response);
  } catch (error) {
    result.error = error;
    yield call(showError);
  }
  return result;
}

function* showError() {
  const notification = notify(
    'Error',
    'Oh.. Something went wrong... Please try later, our tech crew will solve the issue.',
    { autoHide: true, level: 'error' }
  );

  yield notification.show;
}

