const Actions = {
  FETCH_OBJECT: "app.object.FETCH_OBJECT",
  SET_SELECTED_OBJECT: "app.object.SET_SELECTED_OBJECT",
};

export default Actions;

export function setSelectedObject(proposition) {
  return {
    type: Actions.SET_SELECTED_OBJECT,
    proposition,
  };
}

export function fetchObject(publicId) {
  return {
    type: Actions.FETCH_OBJECT,
    publicId,
  };
}
