import React, { Component } from "react";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { toggleTypeSearchFilter, fetchNextPage } from "./actions";
import { Map } from "immutable";

import OutputItem from "./OutputItem";
import { withNamespaces } from "react-i18next";
import { List } from "immutable";
import { pageSize } from "../utils";

@connect(
  (state) => ({
    pages: state.getIn(["app", "search", "pages"]) || List(),
    typeFilter: state.getIn(["app", "search", "filters", "type"]),
    loading: state.getIn(["app", "search", "loading"]) || false,
  }),
  (dispatch) =>
    bindActionCreators(
      { push, toggleTypeSearchFilter, fetchNextPage },
      dispatch,
    ),
)
class OutputSection extends React.Component {
  render() {
    const { pages, fetchNextPage, loading, t, currency, toggleCurrency } =
      this.props;

    // const filteredItems = result.get('items').filter((item) => {
    //     if (!typeFilter) {
    //         return item;
    //     } else if (item.type && typeFilter === localize(locale, item.type)) {
    //         return item;
    //     }
    // });

    return (
      <section className="output">
        <div className="wrapper">
          {counter(t, pages)}
          <p className="subtitle">
            {/*{groupByType(page.get('items')).map(({type, size}) =>*/}
            {/*    <a className={typeFilter !== type && 'filter__inactive'}*/}
            {/*          onClick={() => toggleTypeFilter(type)}>*/}
            {/*        {type} &nbsp;*/}
            {/*    </a>*/}
            {/*)}*/}
          </p>
          <div className="output__wrap">
            <ul className="output__list">
              {pages
                .slice()
                .map((page = Map()) =>
                  page
                    .get("items")
                    .map((object, idx) => (
                      <OutputItem
                        key={[idx]}
                        object={object}
                        currency={currency}
                        toggleCurrency={toggleCurrency}
                      />
                    )),
                )}
            </ul>
            {more(t, pages, fetchNextPage, loading)}
          </div>
        </div>
      </section>
    );
  }
}

export default withNamespaces("results")(OutputSection);

function counter(t, pages) {
  if (pages.size > 0) {
    const size = pages.get(0).get("size");
    // TODO refactor that crap
    return (
      <p className="output__title title">
        {t("titlePrefix")} {size} {calculatePostfix(t, size)}
      </p>
    );
  }
}

function more(t, pages, fetchNextPage, loading) {
  // TODO: extract into component
  if (
    pages.size > 0 &&
    pages.get(pages.size - 1).get("items").length === pageSize
  ) {
    return (
      <div className="output__wrap">
        <button
          className={`button output__more ${loading ? "loading" : ""}`}
          type="button"
          onClick={() => fetchNextPage()}
        >
          {t("more")}
        </button>
      </div>
    );
  }
}

function calculatePostfix(t, size) {
  if (size > 10 && size < 15) {
    return t("titlePostfix3");
  }

  switch (size % 10) {
    case 0:
      return t("titlePostfix3");
    case 1:
      return t("titlePostfix1");
    case 2:
      return t("titlePostfix2");
    case 3:
      return t("titlePostfix2");
    case 4:
      return t("titlePostfix2");
    case 5:
      return t("titlePostfix3");
    case 6:
      return t("titlePostfix3");
    case 7:
      return t("titlePostfix3");
    case 8:
      return t("titlePostfix3");
    case 9:
      return t("titlePostfix3");
    default:
      return t("titlePostfix1");
  }
}

OutputSection.propTypes = {
  pages: PropTypes.object,
  loading: PropTypes.bool,
  typeFilter: PropTypes.object,
  toggleTypeSearchFilter: PropTypes.func,
  fetchNextPage: PropTypes.func,
};
