import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'
import { combineReducers } from 'redux-immutable'
import { reducer as formReducer } from 'redux-form/immutable'
import { reducer as notifications } from 'react-notification-system-redux'

import search from './components/search/reducers';
import object from './components/object/reducers';

const initialState = fromJS({
  locationBeforeTransitions: null
});

function routerReducer(state = initialState, action) {
  if (action.type === LOCATION_CHANGE) {
    return state.merge({
      locationBeforeTransitions: action.payload
    });
  }
  return state;
}

const rootReducer = combineReducers({
  app: combineReducers({
    search, object
  }),
  notifications,
  form: formReducer,
  routing: routerReducer
});

export default rootReducer;
