import { List, Map } from 'immutable'
import Actions from './actions'
import { dayPriceRange, monthPriceRange } from '../../components/utils'

function handleSetSearchTypes(state, { values }) {
  return state.setIn(['types'], values);
}

function handleSetSearchBeaches(state, { values }) {
  return state.setIn(['beaches'], values);
}

function handleSetSearchOptions(state, { values }) {
  return state.setIn(['options'], values);
}

function handleToggleSearchOption(state, { name, value }) {
  return state.updateIn(['query', 'options'], (options = Map()) =>
    value ? options.set(name, value) : options.remove(name)
  );
}

function toggleOne(values = List(), item, checked) {
  const newValues = values.filter((b) => b.id !== item.id);
  return checked ? newValues.set(newValues.size, item) : newValues;
}

function toggleAll(items, checked) {
  return checked ? List(items) : List()
}

function handleToggleSearchBeach(state, { item, checked }) {
  return state.updateIn(['query', 'beaches'], (beaches) =>
    toggleOne(beaches, item, checked)
  );
}

function handleToggleAllSearchBeaches(state, { items, checked }) {
  return state.updateIn(['query', 'beaches'], () =>
    toggleAll(items, checked)
  );
}

function handleToggleSearchType(state, { item, checked }) {
  return state.updateIn(['query', 'types'], (types) =>
    toggleOne(types, item, checked)
  );
}

function handleToggleAllSearchTypes(state, { items, checked }) {
  return state.updateIn(['query', 'types'], () =>
    toggleAll(items, checked)
  );
}

function handleToggleSearchRoom(state, { item, checked }) {
  return state.updateIn(['query', 'rooms'], (rooms) =>
    toggleOne(rooms, item, checked)
  );
}

function handleToggleAllSearchRooms(state, { items, checked }) {
  return state.updateIn(['query', 'rooms'], () =>
    toggleAll(items, checked)
  );
}

function handleToggleSearchPeriod(state) {
  const month = state.getIn(['query', 'month']) || false;
  return state
    .setIn(['query', 'month'], !month)
    .setIn(['query', 'price'], month ? dayPriceRange : monthPriceRange);
}

function handleSetSearchPrice(state, { price }) {
  return state.setIn(['query', 'price'], price);
}

function handleSetSearchDistanceToSea(state, { distanceToSea }) {
  return state.setIn(['query', 'distanceToSea'], distanceToSea)
}

function handleToggleTypeSearchFilter(state, { filter }) {
  const prevFilter = state.getIn(['filters', 'type']);

  if (prevFilter === filter) {
    return state.removeIn(['filters', 'type'])
  } else {
    return state.setIn(['filters', 'type'], filter);
  }
}

function handleAddPage(state, { page }) {
  return state.updateIn(['pages'], (pages = List()) => pages.set(pages.size, page));
}

function handleClearPages(state) {
  return state.updateIn(['pages'], () => List())
}

function handleSetLoading(state, { loading }) {
  return state.set('loading', loading)
}

export default function(state = Map(), action) {
  switch (action.type) {
    case Actions.SET_SEARCH_TYPES:
      return handleSetSearchTypes(state, action);
    case Actions.SET_SEARCH_BEACHES:
      return handleSetSearchBeaches(state, action);
    case Actions.SET_SEARCH_OPTIONS:
      return handleSetSearchOptions(state, action);
    case Actions.TOGGLE_SEARCH_OPTION:
      return handleToggleSearchOption(state, action);
    case Actions.TOGGLE_SEARCH_BEACH:
      return handleToggleSearchBeach(state, action);
    case Actions.TOGGLE_ALL_SEARCH_BEACHES:
      return handleToggleAllSearchBeaches(state, action);
    case Actions.TOGGLE_SEARCH_TYPE:
      return handleToggleSearchType(state, action);
    case Actions.TOGGLE_ALL_SEARCH_TYPES:
      return handleToggleAllSearchTypes(state, action);
    case Actions.TOGGLE_SEARCH_ROOM:
      return handleToggleSearchRoom(state, action);
    case Actions.TOGGLE_ALL_SEARCH_ROOMS:
      return handleToggleAllSearchRooms(state, action);
    case Actions.TOGGLE_SEARCH_PERIOD:
      return handleToggleSearchPeriod(state);
    case Actions.SET_SEARCH_PRICE:
      return handleSetSearchPrice(state, action);
    case Actions.SET_SEARCH_DISTANCE_TO_SEA:
      return handleSetSearchDistanceToSea(state, action);
    case Actions.ADD_SEARCH_PAGE:
      return handleAddPage(state, action);
    case Actions.CLEAR_PAGES:
      return handleClearPages(state);
    case Actions.TOGGLE_TYPE_SEARCH_FILTER:
      return handleToggleTypeSearchFilter(state, action);
    case Actions.SET_LOADING:
      return handleSetLoading(state, action);
    default:
      return state;
  }
}
