import React from 'react'
import PropTypes from 'prop-types'
import { localizeBlind } from '../../../assets/i18n/i18n'

export default class Option extends React.Component {
  render() {
    const { option, checked = false, toggleSearchOption } = this.props;
    return (
      <div className='filter__option ' key={option.id}>
        <label className={'filter__label ' + (checked ? 'checked' : '')} id={'option_' + option.id}>
          <input className='checkbox' type='checkbox' id={option.id}
            onChange={(v) => toggleSearchOption(option.id, v.target.checked)} />
          {localizeBlind(option.name)}
        </label>
      </div>
    )
  }
}

Option.propTypes = {
  option: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  toggleSearchOption: PropTypes.func.isRequired
};
