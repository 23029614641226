import React from "react";
import { initNavigation } from "../utils";
import Header from "./Header";
import Footer from "./Footer";
import SearchSection from "./SearchSection";
import OutputSection from "./OutputSection";
import Title from "./Title";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { withNamespaces } from 'react-i18next';
import { useTranslation } from 'react-i18next';
// import styles from "../../assets/styles/main.css";

class SearchPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    initNavigation();
  }
  constructor(props) {
    super(props);

    this.state = {
      currency: 'THB',
    };

    this.toggleCurrency = this.toggleCurrency.bind(this);
  }

  toggleCurrency() {
    this.setState((prevState) => ({
      currency: prevState.currency === 'USD' ? 'THB' : 'USD',
    }));
  }

  render() {
    const { currency } = this.state;
    const { t } = this.props;
    const keywords = [
      t('keywords.first'),
      t('keywords.second'),
      t('keywords.third'),
      t('keywords.fourth'),
      t('keywords.fifth'),
      t('keywords.sixth'),
      t('keywords.seventh'),
      t('keywords.eighth'),
      t('keywords.ninth'),
      t('keywords.tenth'),
    ];
    const metaKeywords = keywords.join(', ');
    return (
      <div>
        <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} />
          <meta name="keywords" content={metaKeywords} />
        </Helmet>
        <Header currency={this.state.currency} toggleCurrency={this.toggleCurrency} isSearchHeader={true} />
        <Title />
        <SearchSection />
        <OutputSection currency={currency} toggleCurrency={this.toggleCurrency} />
        <Footer />
      </div>
    );
  }
}

export default withNamespaces('searchPage')(SearchPage)
