import $ from "jquery";
import { localize } from "../assets/i18n/i18n";

const currencies = {
  USD: "$",
  EUR: "€",
  CRC: "₡",
  GBP: "£",
  ILS: "₪",
  INR: "₹",
  JPY: "¥",
  KRW: "₩",
  NGN: "₦",
  PHP: "₱",
  PLN: "zł",
  PYG: "₲",
  THB: "฿",
  UAH: "₴",
  VND: "₫",
};

export const pageSize = 12;

export const dayPriceRange = {
  totalMin: 500,
  min: 500,
  max: 120000,
  totalMax: 120000,
  smallStep: 100,
  bigStep: 10000,
  threshold: 20000,
};
export const monthPriceRange = {
  totalMin: 10000,
  min: 10000,
  max: 2000000,
  totalMax: 2000000,
  smallStep: 10000,
  bigStep: 100000,
  threshold: 450000,
};

export function splitOptions(size, options) {
  const option_columns = [];

  options.forEach((option, idx) => {
    const offset = Math.floor(idx / size);

    if (option_columns.length < offset + 1) {
      option_columns.push([]);
    }

    option_columns[offset].push(option);
  });

  return option_columns;
}

export function isChecked(selected, item) {
  return selected.filter((s) => s.id === item.id).size !== 0 ? "checked" : "";
}

export function setCheckedById(id, value) {
  $("#" + id + " input").prop("checked", value);
}

export function checkAll(prefix, items, toggleAllItems) {
  items.forEach(({ id }) => {
    setCheckedById(prefix + id, true);
  });

  toggleAllItems(items, true);
}

export function clearAll(prefix, items, toggleAllItems) {
  items.forEach(({ id }) => {
    setCheckedById(prefix + id, false);
  });

  toggleAllItems(items, false);
}

$(".header-close").hide();
export function toggleMenu() {
  $(this).toggleClass("active");
  $(".header__menu").slideToggle();
  if ($(".header-close").is(":hidden")) {
    $(".btn-menu").hide();
    $(".header-close").show();
  } else {
    $(".btn-menu").show();
    $(".header-close").hide();
  }
  $(document).mouseup(function (e) {
    const div = $(".header__menu");
    const btn = $(".btn-menu");
    if (
      !div.is(e.target) &&
      div.has(e.target).length === 0 &&
      !btn.is(e.target) &&
      btn.has(e.target).length === 0
    ) {
      //   $(".header__menu").slideUp();
      $(".btn-menu").removeClass("active");
    }
  });
  $(".header__menu-item a").click(function () {
    $(".header__menu").slideUp();
    $(".btn-menu").removeClass("active");
    $(".header-close").hide();
    $(".btn-menu").show();
  });
  // $(".header__close").click(function () {
  //   $(".header__menu").slideUp();
  //   $(".btn-menu").removeClass("active");
  //   $(".header-close").hide();
  //   $(".btn-menu").show();
  // });
}

export function initNavigation() {
  if ($(window).width() <= 600) {
    $(".contact__input:eq(2)").attr("placeholder", "Город");
  }
}

export function toCurrency(originalValue, currency, filter = false) {
  let result = "-";

  if (originalValue) {
    if (filter) {
      switch (currency) {
        case "THB":
          result = Math.round(originalValue * 34.28);
          break;
        case "USD":
          result = Math.round(originalValue / 34.28);
          break;
        default:
          break;
      }
    } else {
      switch (currency) {
        case "THB":
          result = originalValue;
          break;
        case "USD":
          result = Math.round(originalValue / 34.28);
          break;
        default:
          break;
      }
    }
  }

  return result;
}

// export function toCurrency(originalValue, currency, filter=False) {
//   let result = "-";

//   if (originalValue) {
//     if (filter) {}
//     switch (currency) {
//       case "THB":
//         result = originalValue;
//         // result = Math.round(originalValue * 34.28);
//         break;
//       case "USD":
//         result = Math.round(originalValue / 34.28);
//         break;
//       default:
//         break;
//     }
//   }

//   return result;
// }

// export function renderPrice(price, currency) {
//   return `${currencies[currency]} ${toCurrency(price, currency)}`;
// }

export function renderMonthPrice(price, currency) {
  return renderPrice(-20, 70, price, currency);
}

export function renderDayPrice(price, currency) {
  return renderPrice(-20, 50, price, currency);
}

export function renderPrice(
  discountPercentage,
  markupPercentage,
  price,
  currency,
) {
  let roundingMultiplier = 1;
  if (currency === "USD") {
    roundingMultiplier = 10;
  } else if (currency === "THB") {
    roundingMultiplier = 100;
  }

  const discountedPrice =
    toCurrency(price, currency) * (1 + discountPercentage / 100);
  const finalPrice = toCurrency(price, currency) * (1 + markupPercentage / 100);

  const roundedDiscountedPrice =
    Math.ceil(discountedPrice / roundingMultiplier) * roundingMultiplier;
  const roundedFinalPrice =
    Math.ceil(finalPrice / roundingMultiplier) * roundingMultiplier;

  return `${currencies[currency]} ${roundedDiscountedPrice} - ${currencies[currency]} ${roundedFinalPrice}`;
}

export function imageKitPreviewUrl(publicId, image) {
  return image && image.filename
    ? `https://ik.imagekit.io/samui/tr:n-preview/${publicId}/${image.filename}`
    : "";
}

export function imageKitOriginalUrl(publicId, image) {
  return image && image.filename
    ? `https://ik.imagekit.io/samui/${publicId}/${image.filename}`
    : "";
}

export function collectImages(images = [], language) {
  let groups = {};

  images.forEach((image, idx) => {
    if (!groups[localize(language, image.title)]) {
      groups[localize(language, image.title)] = [];
    }
    groups[localize(language, image.title)].push({ idx, image });
  });

  return groups;
}

export function validate(values, props) {
  const name = values.get("name");
  const phone = values.get("phone");
  const city = values.get("city");

  const dateRange = values.get("dateRange");

  const errors = {};

  if (!name) {
    errors.name = "name required";
  }

  if (!phone) {
    errors.phone = "phone required";
  }

  if (!city) {
    errors.city = "city required";
  }

  if (!dateRange) {
    errors.dateRange = "please provide date range";
  }

  return errors;
}
