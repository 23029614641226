import React from 'react'
import { DateRangePicker } from "react-dates";
import moment from "moment";

export default class AppDateRangePicker extends React.Component {

  componentWillMount() {
    this.state = {
      startDate: moment(),
      endDate: moment()
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <DateRangePicker
          noBorder={true}
          startDate={this.state.startDate}
          startDateId="start_date_id"
          endDate={this.state.endDate}
          endDateId="end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            this.setState({ startDate, endDate });
            this.props.input.onChange({ startDate, endDate });
          }}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
        />
      </div>
    )
  }
}
