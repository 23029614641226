import PropTypes from 'prop-types'
import React from 'react'
import { withNamespaces } from 'react-i18next'

class CloseButton extends React.Component {
  render() {
    return (
      <button className='filter__close' type='button' onClick={this.props.onClick}>
        {this.props.t('closeFilter')}
      </button>
    )
  }
}

export default withNamespaces('searchForm')(CloseButton)

CloseButton.propTypes = {
  onClick: PropTypes.func
};

