import PropTypes from "prop-types";
import React from "react";
import { initNavigation } from "../utils";
import PriceFilter from "./filters/PriceFilter";
import BeachFilter from "./filters/BeachFilter";
import TypeFilter from "./filters/TypeFilter";
import RoomFilter from "./filters/RoomFilter";
import OptionsFilter from "./filters/OptionsFilter";
import { doSearch } from "./actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withNamespaces } from "react-i18next";
import $ from "jquery";
import { List } from "immutable";
import DistanceToSeaFilter from "./filters/DistanceToSeaFilter";

@connect(
  (state) => ({
    pages: state.getIn(["app", "search", "pages"]) || List(),
  }),
  (dispatch) => bindActionCreators({ doSearch }, dispatch)
)
class SearchSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beach: false,
      type: false,
      room: false,
      distanceToSea: false,
      price: false,
      more: false,
    };
  }

  componentWillMount() {
    if (this.props.pages.size === 0) {
      this.props.doSearch();
    }
  }

  componentDidMount() {
    initNavigation();
  }

  render() {
    const { doSearch, t } = this.props;
    return (
      <div className="wrapper">
        <form className="filter filter_search">
          <BeachFilter
            showChosen={true}
            open={this.state.beach}
            showOrHide={() => this.showBeachFilter()}
          />
          <TypeFilter
            showChosen={true}
            open={this.state.type}
            showOrHide={() => this.showTypeFilter()}
          />
          <RoomFilter
            showChosen={true}
            open={this.state.room}
            showOrHide={() => this.showRoomFilter()}
          />
          <DistanceToSeaFilter
            showChosen={true}
            open={this.state.distanceToSea}
            showOrHide={() => this.showDistanceToSeaFilter()}
          />
          <PriceFilter
            showChosen={true}
            open={this.state.price}
            showOrHide={() => this.showPriceFilter()}
          />
          <button
            className="filter__search button"
            onClick={(e) => {
              e.preventDefault();
              this.hideAllFilters();
              doSearch();
            }}
          >
            {t("search")}
          </button>
          <button
            className={`more-filter ${this.state.more ? "open" : ""}`}
            type="button"
            onClick={() => this.showMoreFilter()}
          >
            {t("additionalFilters")} <i class="fa-solid fa-chevron-down"></i>
          </button>
          <OptionsFilter />
        </form>
      </div>
    );
  }

  toggleFilters(beach, type, room, distanceToSea, price, more) {
    this.setState({ beach, type, room, distanceToSea, price, more });

    if ([beach, type, room, distanceToSea, price, more].some((booL) => booL === true)) {
      const SectionF = this;
      document.addEventListener('click', function hide(event) {
        if (!event.target.closest('.filter__item') && !event.target.closest('.more-filter__wrapper') && !event.target.closest('.more-filter')) {
          if (more) {
            $(".more-filter__wrapper").slideUp();
          }
          beach = type = room = distanceToSea = price = more = false
          SectionF.setState({ beach, type, room, distanceToSea, price, more });
          document.removeEventListener('click', hide);
        }
      });
    };

    if (
      (more && $(".more-filter__wrapper").is(":hidden") === true) ||
      (!more && $(".more-filter__wrapper").is(":hidden") === false)
    ) {
      $(".more-filter__wrapper").slideToggle();
    }
  }

  showBeachFilter() {
    this.toggleFilters(!this.state.beach, false, false, false, false, false);
  }

  showTypeFilter() {
    this.toggleFilters(false, !this.state.type, false, false, false, false);
  }

  showRoomFilter() {
    this.toggleFilters(false, false, !this.state.room, false, false, false);
  }

  showDistanceToSeaFilter() {
    this.toggleFilters(false, false, false, !this.state.distanceToSea, false, false)
  }

  showPriceFilter() {
    this.toggleFilters(false, false, false, false, !this.state.price, false);
  }

  showMoreFilter() {
    this.toggleFilters(false, false, false, false, false, !this.state.more);
  }

  hideAllFilters() {
    this.toggleFilters(false, false, false, false, false, false);
  }

}

export default withNamespaces("results")(SearchSection);

SearchSection.propTypes = {
  doSearch: PropTypes.func,
};
