import PropTypes from 'prop-types'
import React from 'react'
import { splitOptions } from '../../utils'
import { toggleSearchOption, fetchSearchOptions } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Option from './Option'
import { List } from 'immutable'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'
import { getCurrentLocale } from '../../../assets/i18n/i18n'

@connect(
  (state) => ({
    selected: state.getIn(['app', 'search', 'query', 'options']) || List(),
    options: state.getIn(['app', 'search', 'options']) || List()
  }),
  (dispatch) => bindActionCreators({ fetchSearchOptions, toggleSearchOption }, dispatch)
)
class OptionsFilter extends React.Component {

  componentWillMount() {
    if (this.props.options.size === 0) {
      this.props.fetchSearchOptions()
    }
  }

  render() {
    const { options, selected, toggleSearchOption } = this.props;
    const locale = getCurrentLocale();

    return (
      <div className='more-filter__wrapper'>
        {splitOptions(10, _.orderBy(options.toJS(), `name.${locale}`, 'asc')).map((group, idx) =>
          <div className='filter__column' key={idx}>
            {group.map((option) =>
              <Option key={option.id} option={option} checked={selected.get(option.id)} toggleSearchOption={toggleSearchOption} />
            )}
          </div>
        )}
      </div>
    )
  }

}

export default withNamespaces('main')(OptionsFilter)

OptionsFilter.propTypes = {
  fetchSearchOptions: PropTypes.func,
  toggleSearchOption: PropTypes.func,
  options: PropTypes.object,
  selected: PropTypes.array
};
