import React from "react";
import { Field, reduxForm } from "redux-form/immutable";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { sendObjectRequest } from "./actions";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import AppDateRangePicker from "./AppDateRangePicker";
import { validate } from "../utils";

@connect(
  () => ({}),
  (dispatch) => bindActionCreators({ sendObjectRequest }, dispatch)
)
class OfferForm extends React.Component {
  componentWillMount() {
    this.state = {
      startDate: moment(),
      endDate: moment(),
      focusedInput: false,
    };
  }

  render() {
    const { handleSubmit, valid, reset, sendObjectRequest, onFinish, t } =
      this.props;
    return (
      <form
        className="offer__form"
        onSubmit={handleSubmit((form) => {
          sendObjectRequest(window.location.href, form);
          reset();
          onFinish();
        })}
      >
        <Field
          className="contact__input"
          type="text"
          placeholder={t("offerFormName")}
          name="name"
          component="input"
        />
        <Field
          className="contact__input"
          type="text"
          placeholder={t("offerFormPhone")}
          name="phone"
          component="input"
        />
        <Field
          className="contact__input"
          type="text"
          placeholder={t("offerFormCity")}
          name="city"
          component="input"
        />
        <Field
          className="contact__input"
          name="dateRange"
          component={AppDateRangePicker}
        />
        <button
          className="contact__button button"
          type="submit"
          disabled={!valid}
        >
          {t("offerFormSend")}
        </button>
      </form>
    );
  }
}

OfferForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.object,
  valid: PropTypes.object,
  reset: PropTypes.func,
  onFinish: PropTypes.func,
  sendObjectRequest: PropTypes.func,
  submitting: PropTypes.object,
};

export default withNamespaces("main")(
  reduxForm({
    form: "offer",
    validate,
  })(OfferForm)
);
