import React from "react";
import { withNamespaces } from "react-i18next";
import i18n from "i18next";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: i18n.language,
    };
  }
  componentDidMount() {
    const { i18n } = this.props;

    let amoFormScript1, amoFormScript2;

    if (i18n.language === "ru") {
      amoFormScript1 = document.createElement("script");
      amoFormScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1119970",hash:"0c5bab6e88ae442ceb709b1850a949de",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoFormScript2 = document.createElement("script");
      amoFormScript2.id = "amoforms_script_1119970";
      amoFormScript2.async = true;
      amoFormScript2.charset = "utf-8";
      amoFormScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681651282";
    } else if (i18n.language === "en") {
      amoFormScript1 = document.createElement("script");
      amoFormScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1119986",hash:"532c29334a5da197b2be0cd2cebb4b68",locale:"en"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoFormScript2 = document.createElement("script");
      amoFormScript2.id = "amoforms_script_1119986";
      amoFormScript2.async = true;
      amoFormScript2.charset = "utf-8";
      amoFormScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681651097";
    } else {
      amoFormScript1 = document.createElement("script");
      amoFormScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1119970",hash:"0c5bab6e88ae442ceb709b1850a949de",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoFormScript2 = document.createElement("script");
      amoFormScript2.id = "amoforms_script_1119970";
      amoFormScript2.async = true;
      amoFormScript2.charset = "utf-8";
      amoFormScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681651282";
    }

    const amoFormDiv = document.querySelector(".object__form");
    amoFormDiv.appendChild(amoFormScript1);
    amoFormDiv.appendChild(amoFormScript2);
  }

  render() {
    const { t } = this.props;

    return (
      <section className="object__contact">
        <div className="wrapper">
          <p className="title">{t("contactsTitle")}</p>
          <div className="object__form"></div>
          <p className="text">{t("contactsMessage")}</p>
        </div>
      </section>
    );
  }
}

export default withNamespaces("proposition")(Contacts);
