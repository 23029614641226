import PropTypes from 'prop-types'
import React from 'react'
import { toggleSearchType, fetchSearchFormTypes, toggleAllSearchTypes } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List } from 'immutable'
import AbstractFilter from './AbstractFilter'
import { withNamespaces } from 'react-i18next'

@connect(
  (state) => ({
    types: state.getIn(['app', 'search', 'types']) || List(),
    selected: state.getIn(['app', 'search', 'query', 'types']) || List()
  }),
  (dispatch) => bindActionCreators({ fetchSearchFormTypes, toggleSearchType, toggleAllSearchTypes }, dispatch)
)
class TypeFilter extends React.Component {
  componentWillMount() {
    if (this.props.types.size === 0) {
      this.props.fetchSearchFormTypes();
    }
  }

  render() {
    const { types, selected, toggleSearchType, toggleAllSearchTypes, showChosen, t, open, showOrHide } = this.props;
    return (
      <AbstractFilter
        showChosen={showChosen}
        toggleOne={toggleSearchType}
        toggleAll={toggleAllSearchTypes}
        title={t('filtersTypeTitle')}
        prefix={'type_'}
        selected={selected}
        values={types}
        sortByName={true}
        open={open}
        showOrHide={showOrHide}
      />
    )
  }
}

export default withNamespaces('searchForm')(TypeFilter)

TypeFilter.propTypes = {
  showChosen: PropTypes.bool,
  toggleSearchType: PropTypes.func,
  toggleAllSearchTypes: PropTypes.func,
  fetchSearchFormTypes: PropTypes.func,
  types: PropTypes.object,
  selected: PropTypes.object,
  open: PropTypes.bool,
  showOrHide: PropTypes.func
};
