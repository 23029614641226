import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18n from './assets/i18n/i18n'

import Notifications from 'react-notification-system-redux'
import { YMInitializer } from 'react-yandex-metrika'

class Main extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <div id={'app'}>
          {React.cloneElement(this.props.children, this.props)}
        </div>
        <Notifications notifications={this.props.notifications} />
        <YMInitializer accounts={[48059741]} version='2' options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true
        }} />
      </I18nextProvider>
    )
  }
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
  notifications: PropTypes.array
};

Main.contextTypes = {
  router: PropTypes.object
};

export default connect(
  (state) => {
    return {
      notifications: state.get('notifications')
    }
  },
  (dispatch) => bindActionCreators({}, dispatch)
)(Main);

