import React from "react";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { toggleMenu } from "../utils";

@connect(() => ({}), (dispatch) => bindActionCreators({ push }, dispatch))
class Header extends React.Component {
  handleButtonClick = (language) => {
    const { i18n } = this.props;
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
      window.location.reload();
    }
  };
  render() {
    const { isSearchHeader = false, t } = this.props;
    return (
      <header className={"header " + (isSearchHeader ? "header_search" : "")}>
        <a className="header__logo" href="/" />
        <nav className="header__menu">
          <ul>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuMainLink")}>
                {t("menuMain")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href="/">
                {t("menuCatalog")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuTransportsLink")}>
                {t("menuTransports")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuPartnersLink")}>
                {t("menuPartners")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuWhereLink")}>
                {t("menuWhere")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuAboutLink")}>
                {t("menuAbout")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuBlogLink")}>
                {t("menuBlog")}
              </a>
            </li>
            <li className="header__menu-item">
              <a id="external_link" href={t("menuContactsLink")}>
                {t("menuContacts")}
              </a>
            </li>
          </ul>
        </nav>
        <button className="header__currency" onClick={this.props.toggleCurrency}>
          {this.props.currency === 'USD' ? '$ USD' : '฿ THB'}
        </button>
        <div className="header__language">
          <a className={this.props.i18n.language === "en" ? "header__language-active" : ""} id="external_link" onClick={() => this.handleButtonClick('en')}>
            <span></span>
            <span>EN</span>
          </a>
          <a className={this.props.i18n.language === "ru" ? "header__language-active" : ""} id="external_link" onClick={() => this.handleButtonClick('ru')}>
            <span></span>
            <span>RU</span>
          </a>
        </div>

        <button className="btn-menu" type="button" onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </button>
        <button
          className="header-close"
          type="button"
          onClick={toggleMenu}
          hidden
        >
          <i class="fa-solid fa-x"></i>
        </button>
      </header>
    );
  }
}

export default withNamespaces("header")(Header);

Header.propTypes = {
  push: PropTypes.func,
  isSearchHeader: PropTypes.bool,
};
