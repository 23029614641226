import React, { useState } from 'react';
import PropTypes from "prop-types";
import { imageKitOriginalUrl, renderDayPrice, renderMonthPrice } from "../utils";
import Mokal from "react-responsive-modal";
import { connect } from "react-redux";
import { List } from 'immutable'
import Header from "../search/Header";
import OfferForm from "../search/OfferForm";
import { withNamespaces } from "react-i18next";
import { localizeBlind } from "../../assets/i18n/i18n";
import { Map } from "immutable";
import i18n from "i18next";

@connect(
  (state) => ({
    selected: state.getIn(["app", "object", "selected"]) || Map(),
  }),
  () => ({})
)
class ObjectCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: i18n.language,
      open: false,
      currency: 'THB',
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.toggleCurrency = this.toggleCurrency.bind(this);

  }

  onOpenModal() {
    this.setState({ open: true });
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  componentDidMount() {
    const { i18n } = this.props;

    let amoButtonScript1, amoButtonScript2;

    if (i18n.language === "ru") {
      amoButtonScript1 = document.createElement("script");
      amoButtonScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1121178",hash:"d61ec8e31a5dfc87b4eeaf977fc51098",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoButtonScript2 = document.createElement("script");
      amoButtonScript2.id = "amoforms_script_1121178";
      amoButtonScript2.async = true;
      amoButtonScript2.charset = "utf-8";
      amoButtonScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681828457";
    } else if (i18n.language === "en") {
      amoButtonScript1 = document.createElement("script");
      amoButtonScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1121186",hash:"4b30b31bd794dca8fc719dd0a6f7c8e6",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoButtonScript2 = document.createElement("script");
      amoButtonScript2.id = "amoforms_script_1121186";
      amoButtonScript2.async = true;
      amoButtonScript2.charset = "utf-8";
      amoButtonScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681829046";
    } else {
      amoButtonScript1 = document.createElement("script");
      amoButtonScript1.innerHTML = `!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1121178",hash:"d61ec8e31a5dfc87b4eeaf977fc51098",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");`;
      amoButtonScript2 = document.createElement("script");
      amoButtonScript2.id = "amoforms_script_1121178";
      amoButtonScript2.async = true;
      amoButtonScript2.charset = "utf-8";
      amoButtonScript2.src =
        "https://forms.amocrm.ru/forms/assets/js/amoforms.js?1681828457";
    }

    const amoButtonDiv = document.querySelector(".object__button");
    amoButtonDiv.appendChild(amoButtonScript1);
    amoButtonDiv.appendChild(amoButtonScript2);
  }

  toggleCurrency() {
    this.setState(prevState => ({
      currency: prevState.currency === 'USD' ? 'THB' : 'USD'
    }));
  }


  render() {
    const { selected, publicId, t } = this.props;
    const { open } = this.state;
    const objectOptions = selected.get('options') || List();

    return (
      <section className="object__banner">
        <Header currency={this.state.currency} toggleCurrency={this.toggleCurrency} />
        <div className="object__cover">
          <img src={imageKitOriginalUrl(publicId, selected.get("mainImage"))} />
        </div>
        <div className="wrapper object__wrapper">
          <div className="object__card">
            <p className="subtitle object__subtitle">{t("objectBeach")}</p>
            <p className="title object__title">
              {localizeBlind(selected.get("beach"))}
              <button onClick={this.toggleCurrency}>
                {/* <span
    style={{
      opacity: this.state.currency === 'USD' ? 0.5 : 1,
      marginRight: '10px', 
    }}
  >
    ฿ THB
  </span>
  <span
    style={{
      opacity: this.state.currency === 'THB' ? 0.5 : 1,
    }}
  >
    $ USD
  </span> */}
              </button>
            </p>
            <p className="object__card-id">{selected.get('id')}</p>
            <div className="object__card-image">
              <img src={imageKitOriginalUrl(publicId, selected.get("mainImage"))} />
            </div>
            <h1 className="description">
              {localizeBlind(selected.get("briefly"))}
            </h1>
            <div className='object__important'>
              <p className='object__important-item'>{selected.get('sleepingRoomCount')}</p>
              <p className='object__important-item'>{selected.get('washRoomCount')}</p>
              <p className='object__important-item'>{objectOptions['swimmingPool'] ? t("itemYes") : t("itemNo")}</p>
              <p className='object__important-item'>{selected.get('distanceToSea')} {t("itemMeter")}</p>
            </div>
            <div className="object__price-wrap">
              {selected.get("dayPrice") && (
                <p>
                  {t("objectDay")}
                  <span>{renderDayPrice(selected.get("dayPrice"), this.state.currency)}</span>
                </p>
              )}
              {selected.get("monthPrice") && (
                <p>
                  {t("objectMonth")}
                  <span>{renderMonthPrice(selected.get("monthPrice"), this.state.currency)}</span>
                </p>
              )}

            </div>
            <div className="object__button"></div>

            <span className="object__price-warning">{t("warning")}</span>
          </div>
        </div>
      </section>
    );
  }
}

export default withNamespaces("proposition")(ObjectCard);

ObjectCard.propTypes = {
  selected: PropTypes.object,
  publicId: PropTypes.string,
};
