import Actions, { setSearchTypes, setSearchBeaches, setSearchOptions, clearPages, setLoading } from './actions'
import { call, select, put, takeLatest } from 'redux-saga/effects'
import { getBeaches, getOptions, getTypes, search } from '../../datasources/app-datasource'
import { getOrDisplayError } from '../../utils/sagas-utils'
import { notify } from '../../utils/notification'
import { addSearchPage } from './actions'
import { Map } from 'immutable'
import { getCurrentLocale } from '../../assets/i18n/i18n'
import { postForm } from '../../datasources/app-datasource'
import { selectSearchQuery, selectPages } from '../selectors'
import { pageSize } from '../utils'

export default function*() {
  yield [
    takeLatest(Actions.FETCH_SEARCH_TYPES, handleFetchSearchTypes),
    takeLatest(Actions.FETCH_SEARCH_BEACHES, handleFetchSearchBeaches),
    takeLatest(Actions.FETCH_SEARCH_OPTIONS, handleFetchSearchOptions),
    takeLatest(Actions.DO_SEARCH, handleDoSearch),
    takeLatest(Actions.SEND_OBJECT_REQUEST, handleSendObjectRequest),
    takeLatest(Actions.FETCH_NEXT_PAGE, handleFetchNextPage)
  ]
}

function toPayload(data = Map(), pageNumber, pageSize) {
  return Map({
    locale: getCurrentLocale(),
    beaches: data.get('beaches') && data.get('beaches').map(({ id }) => id),
    types: data.get('types') && data.get('types').map(({ id }) => id),
    rooms: data.get('rooms') && data.get('rooms').map(({ id }) => id),
    price: data.get('price'),
    distanceToSea: data.get('distanceToSea'),
    month: data.get('month'),
    options: data.get('options'),
    pageNumber,
    pageSize
  });
}

function* handleDoSearch() {
  const query = yield select(selectSearchQuery);
  yield put(setLoading(true));

  const result = yield call(search, toPayload(query, 0, pageSize));

  if (!result.data || result.data.size === 0) {
    const notification = notify(
      'Message',
      'Can\'t find object for your query',
      { autoHide: true, level: 'success' }
    );

    yield notification.show;
  } else {
    yield put(clearPages());
    yield put(addSearchPage(Map(result.data)));
  }

  yield put(setLoading(false));
}

function* handleFetchNextPage() {
  const pages = yield select(selectPages);
  const query = yield select(selectSearchQuery);
  yield put(setLoading(true));

  const result = yield call(search, toPayload(query, pages.size, pageSize));

  if (!result.data || result.data.size === 0) {
    const notification = notify(
      'Message',
      'Can\'t find object for your query',
      { autoHide: true, level: 'success' }
    );

    yield notification.show;
  } else {
    yield put(addSearchPage(Map(result.data)));
  }

  yield put(setLoading(false));
}

function* handleFetchSearchOptions() {
  yield call(getOrDisplayError, getOptions, setSearchOptions);
}

function* handleFetchSearchTypes() {
  yield call(getOrDisplayError, getTypes, setSearchTypes);
}

function* handleFetchSearchBeaches() {
  yield call(getOrDisplayError, getBeaches, setSearchBeaches);
}

export function* handleSendObjectRequest({ url, form }) {
  yield call(postForm, form.set('url', url));

  const notification = notify(
    'Thank you!',
    'Our crew will call you soon!',
    { autoHide: true, level: 'info' }
  );

  yield notification.show;
}
