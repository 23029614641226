import { call, put, takeLatest } from 'redux-saga/effects'
import Actions, { setSelectedObject } from './actions'
import { getObject } from '../../datasources/app-datasource'

export default function*() {
  yield [
    takeLatest(Actions.FETCH_OBJECT, handleFetchObject)
  ]
}

export function* handleFetchObject({ publicId }) {
  const { data } = yield call(getObject, publicId);

  if (data) {
    yield put(setSelectedObject(data))
  }
}
