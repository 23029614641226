import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import Lightbox from 'react-image-lightbox'
import { withNamespaces } from 'react-i18next'
import { getCurrentLocale, localize } from '../../assets/i18n/i18n'
import { collectImages, imageKitOriginalUrl, imageKitPreviewUrl } from '../../components/utils'
import { Map } from 'immutable'

@connect(
  (state) => ({
    selected: state.getIn(['app', 'object', 'selected']) || Map({ additionalImages: [] })
  }),
  () => ({})
)
class GallerySection extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {
    const { selected, publicId, t } = this.props;
    const locale = getCurrentLocale();
    const { isOpen, photoIndex } = this.state;
    const additionalImages = collectImages(selected.get('additionalImages'), locale);

    let images = [];

    if (selected.get('additionalImages')) {
      images = selected.get('additionalImages').map((image) =>
        ({ title: localize(locale, image.title), url: imageKitOriginalUrl(publicId, image) })
      );
    }

    return (
      <section className='object__overview'>
        <div className='wrapper'>
          <p className='title object__title'>
            {t('galleryTitle')}
          </p>
          {Object.keys(additionalImages).map((group) =>
            <div className='overview__item' key={group}>
              <p className='subtitle object__subtitle'>{group}</p>
              {additionalImages[group].map(({ image, idx }) =>
                <div className='overview__image' key={idx} onClick={() => this.setState({ isOpen: true, photoIndex: idx })}>
                  <img src={imageKitPreviewUrl(publicId, image)} />
                </div>
              )}
            </div>
          )}

          {selected.get('videoLink') && (
            <div className='overview__item'>
              <p className='subtitle object__subtitle'>
                {t('galleryVideo')}
              </p>
              <ReactPlayer
                height={window.innerWidth * 0.5}
                width='100%'
                className='video-wrap'
                url={selected.get('videoLink')}
                controls='true'
              />
              <p className='video-link'>{t('textVideo')} <a href={selected.get('videoLink')} target="_blank">{t("linkVideo")}</a>.</p>
            </div>
          )}
        </div>
        {isOpen && (
          <Lightbox
            imageTitle={images[photoIndex].title}
            mainSrc={images[photoIndex].url}
            nextSrc={images[(photoIndex + 1) % images.length].url}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </section>

    )
  }
}

export default withNamespaces('proposition')(GallerySection)

GallerySection.propTypes = {
  selected: PropTypes.object,
  publicId: PropTypes.string
};

