import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { withNamespaces } from "react-i18next";
import { localizeBlind } from "../../assets/i18n/i18n";
import { Map } from "immutable";

@connect(
  (state) => ({
    selected: state.getIn(["app", "object", "selected"]) || Map(),
  }),
  () => ({})
)
class Description extends React.Component {
  render() {
    const { selected } = this.props;

    return (
      <section className="object__about">
        <div className="wrapper">
          <p className="title object__title">
            {localizeBlind(selected.get("title"))}
          </p>
          <p className="text">
            {renderHTML(localizeBlind(selected.get("description")))}
          </p>
        </div>
      </section>
    );
  }
}

export default withNamespaces("results")(Description);

Description.propTypes = {
  selected: PropTypes.object,
};
