import PropTypes from 'prop-types'
import React from 'react'
import { toggleSearchBeach, fetchSearchBeaches, toggleAllSearchBeaches } from '../actions'
import { bindActionCreators } from 'redux'
import { List } from 'immutable'
import { connect } from 'react-redux'
import AbstractFilter from './AbstractFilter'
import { withNamespaces } from 'react-i18next'

@connect(
  (state) => ({
    beaches: state.getIn(['app', 'search', 'beaches']) || List(),
    selected: state.getIn(['app', 'search', 'query', 'beaches']) || List()
  }),
  (dispatch) => bindActionCreators({ fetchSearchBeaches, toggleSearchBeach, toggleAllSearchBeaches }, dispatch)
)
class BeachFilter extends React.Component {
  componentWillMount() {
    if (this.props.beaches.size === 0) {
      this.props.fetchSearchBeaches();
    }
  }

  render() {
    const { beaches, selected, toggleSearchBeach, toggleAllSearchBeaches, showChosen, t, open, showOrHide } = this.props;

    return (
      <AbstractFilter
        showChosen={showChosen}
        toggleOne={toggleSearchBeach}
        toggleAll={toggleAllSearchBeaches}
        title={t('filtersBeachTitle')}
        prefix={'beach_'}
        selected={selected}
        sortByName={true}
        values={beaches}
        open={open}
        showOrHide={showOrHide}
      />
    )
  }
}

export default withNamespaces('searchForm')(BeachFilter)

BeachFilter.propTypes = {
  showChosen: PropTypes.bool,
  fetchSearchBeaches: PropTypes.func,
  toggleSearchBeach: PropTypes.func,
  toggleAllSearchBeaches: PropTypes.func,
  beaches: PropTypes.object,
  selected: PropTypes.object,
  open: PropTypes.bool,
  showOrHide: PropTypes.func
};
