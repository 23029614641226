import React from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

@connect(() => ({}), (dispatch) => bindActionCreators({ push }, dispatch))
class Footer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="footer footer_full">
        <div className="footer__left">
          <p className="footer__contact">
            {t("contactLabel")}
            <a href="https://wa.me/66657431184" target="_blank">
              {t("contact")}
            </a>
            {/* <a href="https://wa.me/66610193823" target="_blank">{t("contact")}</a> */}
          </p>
          <div className="footer__social">
            {/* TODO: move into db an fetch*/}
            <a
              className="footer__social-item"
              href="https://vk.com/samui.rest"
              target="_blank"
            />
            <a
              className="footer__social-item"
              href="https://www.youtube.com/channel/UCsddMCKPa7nwt_M8nP1QpFQ"
              target="_blank"
            />
            <a
              className="footer__social-item"
              href="https://m.me/1789924674647660"
              target="_blank"
            />
            <a
              className="footer__social-item"
              href="https://www.instagram.com/samui.rest/"
              target="_blank"
            />
          </div>
        </div>
        <div className="footer__right">
          <p className="footer__contact">
            SAMUI REST Co., Ltd <br />
            IT Compleх, 56 Moo 4, Maret, Koh Samui <br />
            Surat Thani, Thailand, 84310 <br />
            Tax № 0845559007539
          </p>
        </div>
        <div className="footer__center">
          <a className="footer__logo" href="https://samui.rest" />
          <p className="footer__copy">{t("copy")}</p>
        </div>
      </footer>
    );
  }
}

export default withNamespaces("footer")(Footer);

Footer.propTypes = {
  push: PropTypes.func,
};
