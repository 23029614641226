const Actions = {
  DO_SEARCH: 'app.search.DO_SEARCH',
  TOGGLE_TYPE_SEARCH_FILTER: 'app.search.TOGGLE_TYPE_SEARCH_FILTER',
  SEND_OBJECT_REQUEST: 'app.search.SEND_OBJECT_REQUEST',
  FETCH_SEARCH_TYPES: 'app.search.FETCH_SEARCH_TYPES',
  TOGGLE_SEARCH_TYPE: 'app.search.TOGGLE_SEARCH_TYPE',
  TOGGLE_ALL_SEARCH_TYPES: 'app.search.TOGGLE_ALL_SEARCH_TYPES',
  TOGGLE_SEARCH_ROOM: 'app.search.TOGGLE_SEARCH_ROOM',
  TOGGLE_ALL_SEARCH_ROOMS: 'app.search.TOGGLE_ALL_SEARCH_ROOMS',
  TOGGLE_SEARCH_PERIOD: 'app.search.TOGGLE_SEARCH_PERIOD',
  SET_SEARCH_PRICE: 'app.search.SET_SEARCH_PRICE',
  SET_SEARCH_DISTANCE_TO_SEA: 'app.search.SET_SEARCH_DISTANCE_TO_SEA',
  TOGGLE_SEARCH_OPTION: 'app.search.TOGGLE_SEARCH_OPTION',
  FETCH_SEARCH_OPTIONS: 'app.search.FETCH_SEARCH_OPTIONS',
  FETCH_SEARCH_BEACHES: 'app.search.FETCH_SEARCH_BEACHES',
  SET_SEARCH_TYPES: 'app.search.SET_SEARCH_TYPES',
  SET_SEARCH_BEACHES: 'app.search.SET_SEARCH_BEACHES',
  SET_SEARCH_OPTIONS: 'app.search.SET_SEARCH_OPTIONS',
  TOGGLE_SEARCH_BEACH: 'app.search.TOGGLE_SEARCH_BEACH',
  TOGGLE_ALL_SEARCH_BEACHES: 'app.search.TOGGLE_ALL_SEARCH_BEACHES',
  ADD_SEARCH_PAGE: 'app.search.ADD_SEARCH_PAGE',
  FETCH_NEXT_PAGE: 'app.search.FETCH_NEXT_PAGE',
  CLEAR_PAGES: 'app.search.CLEAR_PAGES',
  SET_LOADING: 'app.search.SET_LOADING'
};

export default Actions;

export function setLoading(loading) {
  return {
    type: Actions.SET_LOADING,
    loading
  }
}

export function doSearch() {
  return {
    type: Actions.DO_SEARCH
  }
}

export function clearPages() {
  return {
    type: Actions.CLEAR_PAGES
  }
}

export function toggleTypeSearchFilter(filter) {
  return {
    type: Actions.TOGGLE_TYPE_SEARCH_FILTER,
    filter
  }
}

export function sendObjectRequest(url, form) {
  return {
    type: Actions.SEND_OBJECT_REQUEST,
    url,
    form
  }
}

export function fetchSearchFormTypes() {
  return {
    type: Actions.FETCH_SEARCH_TYPES
  };
}

export function toggleSearchType(item, checked) {
  return {
    type: Actions.TOGGLE_SEARCH_TYPE,
    item,
    checked
  }
}

export function toggleAllSearchTypes(items, checked) {
  return {
    type: Actions.TOGGLE_ALL_SEARCH_TYPES,
    items,
    checked
  }
}

export function toggleSearchRoom(item, checked) {
  return {
    type: Actions.TOGGLE_SEARCH_ROOM,
    item,
    checked
  }
}

export function toggleAllSearchRooms(items, checked) {
  return {
    type: Actions.TOGGLE_ALL_SEARCH_ROOMS,
    items,
    checked
  }
}

export function toggleSearchPeriod() {
  return {
    type: Actions.TOGGLE_SEARCH_PERIOD
  }
}

export function setSearchPrice(price) {
  return {
    type: Actions.SET_SEARCH_PRICE,
    price
  }
}

export function setSearchDistanceToSea(distanceToSea) {
  return {
    type: Actions.SET_SEARCH_DISTANCE_TO_SEA,
    distanceToSea
  }
}

export function toggleSearchOption(name, value) {
  return {
    type: Actions.TOGGLE_SEARCH_OPTION,
    name,
    value
  }
}

export function fetchSearchOptions() {
  return {
    type: Actions.FETCH_SEARCH_OPTIONS
  };
}

export function addSearchPage(page) {
  return {
    type: Actions.ADD_SEARCH_PAGE,
    page
  }
}

export function fetchNextPage() {
  return {
    type: Actions.FETCH_NEXT_PAGE
  }
}

export function fetchSearchBeaches() {
  return {
    type: Actions.FETCH_SEARCH_BEACHES
  };
}

export function setSearchOptions(values) {
  return {
    type: Actions.SET_SEARCH_OPTIONS,
    values
  };
}

export function setSearchTypes(values) {
  return {
    type: Actions.SET_SEARCH_TYPES,
    values
  };
}

export function setSearchBeaches(values) {
  return {
    type: Actions.SET_SEARCH_BEACHES,
    values
  };
}

export function toggleSearchBeach(item, checked) {
  return {
    type: Actions.TOGGLE_SEARCH_BEACH,
    item,
    checked
  }
}

export function toggleAllSearchBeaches(items, checked) {
  return {
    type: Actions.TOGGLE_ALL_SEARCH_BEACHES,
    items,
    checked
  }
}
