import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {reactI18nextModule} from 'react-i18next';

import resources from './resources'

i18n.use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        fallbackLng: 'ru',
        debug: false,
        interpolation: {escapeValue: false,},
        react: {wait: true,},
        resources
    });

export default i18n;

i18n.init({});

export function getCurrentLocale() {
    return i18n.language && (i18n.language.startsWith('en') || i18n.language.startsWith('EN')) ? 'EN' : 'RU';
}

export function localizeBlind(message) {
    return message ? message[getCurrentLocale()] : ''
}

export function localize(locale, message) {
    return message ? message[locale] : ''
}
