require('./assets/fonts/fonts.scss');
require('./assets/styles/main.scss');

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import { browserHistory, IndexRoute, Route, Router } from 'react-router'

import SearchPage from './components/search/SearchPage'
import ObjectPage from './components/object/ObjectPage'

import App from './App'
import store from './store'
import { routes } from './utils/app-routes'

import 'react-dates/initialize';

/* eslint-disable no-undef */
global.__ROOT_CONTEXT__ = '/';
const contextRoot = __ROOT_CONTEXT__;

const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState(state) {
    return state.get('routing').toJS();
  }
});

const router = (
  <Provider store={store}>
    <Router history={history} >
      <Route path={contextRoot} component={App}>
        <IndexRoute component={SearchPage} />
        <Route path={routes.OBJECT} component={ObjectPage} />
      </Route>
      {/*<Route path="*" component={NotFoundPage} />*/}
    </Router>
  </Provider>
);

render(router, document.getElementById('root'));
