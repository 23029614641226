import PropTypes from "prop-types";
import React from "react";
import { toggleSearchRoom, toggleAllSearchRooms } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { List } from "immutable";
import AbstractFilter from "./AbstractFilter";
import { withNamespaces } from "react-i18next";

@connect(
  (state) => ({
    selected: state.getIn(["app", "search", "query", "rooms"]) || List(),
  }),
  (dispatch) =>
    bindActionCreators({ toggleSearchRoom, toggleAllSearchRooms }, dispatch),
)
class RoomFilter extends React.Component {
  render() {
    const {
      selected,
      toggleSearchRoom,
      toggleAllSearchRooms,
      showChosen,
      t,
      open,
      showOrHide,
    } = this.props;

    return (
      <AbstractFilter
        showChosen={showChosen}
        toggleOne={toggleSearchRoom}
        toggleAll={toggleAllSearchRooms}
        title={t("filtersRoomTitle")}
        prefix={"room_"}
        selected={selected}
        values={List([
          // TODO: remove hardcode
          {
            id: 0,
            name: { RU: t("filtersOption0Room"), EN: t("filtersOption0Room") },
          },
          {
            id: 1,
            name: { RU: t("filtersOption1Room"), EN: t("filtersOption1Room") },
          },
          {
            id: 2,
            name: { RU: t("filtersOption2Room"), EN: t("filtersOption2Room") },
          },
          {
            id: 3,
            name: { RU: t("filtersOption3Room"), EN: t("filtersOption3Room") },
          },
          {
            id: 4,
            name: { RU: t("filtersOption4Room"), EN: t("filtersOption4Room") },
          },
          {
            id: 5,
            name: { RU: t("filtersOption5Room"), EN: t("filtersOption5Room") },
          },
          {
            id: 6,
            name: { RU: t("filtersOption6Room"), EN: t("filtersOption6Room") },
          },
          {
            id: 7,
            name: { RU: t("filtersOption7Room"), EN: t("filtersOption7Room") },
          },
          {
            id: 8,
            name: { RU: t("filtersOption8Room"), EN: t("filtersOption8Room") },
          },
        ])}
        open={open}
        showOrHide={showOrHide}
      />
    );
  }
}

export default withNamespaces("searchForm")(RoomFilter);

RoomFilter.propTypes = {
  showChosen: PropTypes.bool,
  toggleSearchRoom: PropTypes.func,
  toggleAllSearchRooms: PropTypes.func,
  selected: PropTypes.object,
  open: PropTypes.bool,
  showOrHide: PropTypes.func,
};
