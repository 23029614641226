import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";

import { fetchObject } from "./actions";
import { initNavigation } from "../utils";
import $ from "jquery";
import { withNamespaces } from 'react-i18next'

import { localizeBlind } from "../../assets/i18n/i18n";
import { Map } from "immutable";
import { Helmet } from "react-helmet";
import Options from "./Options";
import Gallery from "./Gallery";
import Footer from "../search/Footer";
import Contacts from "./Contacts";
import ObjectCard from "./ObjectCard";
import Description from "./Description";

@connect(
  (state) => ({
    selected: state.getIn(["app", "object", "selected"]) || Map(),
  }),
  (dispatch) => bindActionCreators({ push, fetchObject }, dispatch)
)
class ObjectPage extends React.Component {
  componentWillMount() {
    this.props.fetchObject(this.props.params.publicId);
    // TODO: remove that direct crap, that break direct access to an object page
    // ym('hit', `/object/${this.props.params.publicId}`)
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    initNavigation();
  }

  render() {
    const { t, selected } = this.props;
    const type = selected.get("type");
    const beach = selected.get("beach"); // Изменил на beach
    const sleepingRoomCount = selected.get("sleepingRoomCount"); // Изменил на sleepingRoomCount

    const titleParams = {
      type: localizeBlind(type),
      beach: localizeBlind(beach),
      sleepingRoomCount: sleepingRoomCount
    };

    const title = t("title", titleParams);
    const description = t("description", titleParams);
    const keywords = [
      t("keywords.first", titleParams),
      t("keywords.second", titleParams),
      t("keywords.third", titleParams),
      t("keywords.fourth", titleParams)
    ];
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords.join(',')} />
        </Helmet>
        <ObjectCard publicId={this.props.params.publicId} />
        <Description />
        <Gallery publicId={this.props.params.publicId} />
        <Options />
        <Contacts />
        <Footer />
      </div>
    );
  }
}

export default withNamespaces("objectPage")(ObjectPage);

ObjectPage.propTypes = {
  params: PropTypes.object,
  fetchObject: PropTypes.func,
  selected: PropTypes.object,
};

