import PropTypes from "prop-types";
import React from "react";
import { toggleSearchPeriod, setSearchPrice } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InputRange from "react-input-range";
import { dayPriceRange, monthPriceRange } from "../../utils";
import { toCurrency } from "../../utils";
import { withNamespaces } from "react-i18next";

@connect(
  (state) => ({
    month: state.getIn(["app", "search", "query", "month"]) || false,
    price: state.getIn(["app", "search", "query", "price"]) || dayPriceRange,
  }),
  (dispatch) =>
    bindActionCreators({ toggleSearchPeriod, setSearchPrice }, dispatch),
)
class PriceFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderMinValue: 0,
      sliderMaxValue: 100,
      sliderValue: {
        min: 0,
        max: 100
      },
      minPriceInput: props.price.min,
      maxPriceInput: props.price.max,
      minPriceInputUSD: toCurrency(props.price.min, "USD"),
      maxPriceInputUSD: toCurrency(props.price.max, "USD"),
    };
  }
  render() {
    const {
      month,
      toggleSearchPeriod,
      price,
      setSearchPrice,
      t,
      open,
      showOrHide,
    } = this.props;
    const { minPriceInput, maxPriceInput, minPriceInputUSD, maxPriceInputUSD, sliderMinValue, sliderValue, sliderMaxValue } =
      this.state;

    return (
      <div className={`filter__item price ${open ? "open" : ""}`}>
        <p className="filter__button button" onClick={() => showOrHide()}>
          {t("filtersPrice")}
          <i class="fa-solid fa-chevron-down"></i>
        </p>
        <div
          className="filter__dropdown price-button"
          style={open ? { display: "block" } : { display: "none" }}
        >
          <button
            className={"filter__switch price-switch " + (month ? "active" : "")}
            onClick={(e) => {
              e.preventDefault();
              toggleSearchPeriod();
              this.handlePeriodToggle();
            }}
          >
            <span />
          </button>

          {/* BUG: strange search price unsync */}
          <InputRange
            value={sliderValue}
            minValue={sliderMinValue}
            maxValue={sliderMaxValue}
            onChange={(value) => {
              const currentPrice = this.transformSliderValueToPrice(
                value.min,
                value.max,
                66,
                month ? monthPriceRange.threshold : dayPriceRange.threshold,
                month ? monthPriceRange.totalMin : dayPriceRange.totalMin,
                month ? monthPriceRange.totalMax : dayPriceRange.totalMax,
              )
              setSearchPrice(currentPrice);
              this.setState({
                sliderValue: value,
                minPriceInput: currentPrice.min,
                maxPriceInput: currentPrice.max,
                minPriceInputUSD: toCurrency(currentPrice.min, "USD"),
                maxPriceInputUSD: toCurrency(currentPrice.max, "USD"),
              });
            }}
            step={1}
          />
          <div className="filter__text">
            <span>{t("filtersFrom")}</span>
            <span>{t("filtersTo")}</span>
          </div>

          <p className="filter__value">
            <input
              type="number"
              id="from-thb"
              value={minPriceInput}
              onChange={(e) =>
                this.handlePriceInputChange(e.target.value, "THB", "min")
              }
              onBlur={() => this.handlePriceInputBlur("min")}
              disabled={this.state.isSliderActive}
            />
            <input
              type="number"
              id="to-thb"
              value={maxPriceInput}
              onChange={(e) =>
                this.handlePriceInputChange(e.target.value, "THB", "max")
              }
              onBlur={() => this.handlePriceInputBlur("max")}
              disabled={this.state.isSliderActive}
            />
            <span>฿</span>
          </p>

          <p className="filter__value">
            <input
              type="number"
              id="from-usd"
              value={minPriceInputUSD}
              onChange={(e) =>
                this.handlePriceInputChange(e.target.value, "USD", "min")
              }
              onBlur={() => this.handlePriceInputBlur("min")}
              disabled={this.state.isSliderActive}
            />
            <input
              type="number"
              id="to-usd"
              value={maxPriceInputUSD}
              onChange={(e) =>
                this.handlePriceInputChange(e.target.value, "USD", "max")
              }
              onBlur={() => this.handlePriceInputBlur("max")}
              disabled={this.state.isSliderActive}
            />
            <span>$</span>
          </p>
          <input id="price-checkbox" type="checkbox" hidden></input>

          <p
            className="filter__close price-close"
            onClick={() => showOrHide()}
          ></p>
          <p className="filter__all filter-price">{t("filtersPriceDay")}</p>
          <p className="filter__clean filter-price">{t("filtersPriceMonth")}</p>
        </div>
      </div>
    );
  }

  calculatePriceRangeStep(priceLeftBorder, priceRightBorder, countOfSteps) {
    return (priceRightBorder - priceLeftBorder) / countOfSteps
  }

  calculatePriceRangeValue(priceLeftBorder, priceRightBorder, percentCount, percent) {
    return Math.round(this.calculatePriceRangeStep(priceLeftBorder, priceRightBorder, percentCount) * percent)
  }


  // TODO: poor naming of left and right values
  transformSliderValueToPrice(minValue, maxValue, thresholdValue, thresholdPrice, minPrice, maxPrice) {
    const price = {
      min: minPrice,
      max: maxPrice
    }
    if (minValue <= thresholdValue) {
      price.min = this.calculatePriceRangeValue(minPrice, thresholdPrice, thresholdValue, minValue) + minPrice
    } else {
      price.min = this.calculatePriceRangeValue(thresholdPrice, maxPrice, (100 - thresholdValue), minValue - thresholdValue) + thresholdPrice
    }
    if (maxValue <= thresholdValue) {
      price.max = this.calculatePriceRangeValue(minPrice, thresholdPrice, thresholdValue, maxValue) - minPrice
    } else {
      price.max = this.calculatePriceRangeValue(thresholdPrice, maxPrice, (100 - thresholdValue), maxValue - thresholdValue) + thresholdPrice
    }

    return price
  }

  handlePriceInputChange(newValue, currency, rangeType) {
    const { setSearchPrice } = this.props;
    const newPriceState = { ...this.state };

    if (currency === "THB") {
      if (rangeType === "min") {
        newPriceState.minPriceInput = newValue;
        newPriceState.minPriceInputUSD = toCurrency(newValue, "USD");
      } else {
        newPriceState.maxPriceInput = newValue;
        newPriceState.maxPriceInputUSD = toCurrency(newValue, "USD");
      }
    } else {
      if (rangeType === "min") {
        newPriceState.minPriceInputUSD = newValue;
        newPriceState.minPriceInput = toCurrency(newValue, "THB", true);
      } else {
        newPriceState.maxPriceInputUSD = newValue;
        newPriceState.maxPriceInput = toCurrency(newValue, "THB", true);
      }
    }

    this.setState(newPriceState);

    setSearchPrice({
      min: newPriceState.minPriceInput,
      max: newPriceState.maxPriceInput,
    });
  }

  handlePriceInputBlur(rangeType) {
    const { setSearchPrice } = this.props;
    const newPriceState = { ...this.state };

    if (rangeType === "min") {
      if (newPriceState.minPriceInput < dayPriceRange.totalMin) {
        newPriceState.minPriceInput = dayPriceRange.totalMin;
        newPriceState.minPriceInputUSD = toCurrency(
          dayPriceRange.totalMin,
          "USD",
        );
      } else if (newPriceState.minPriceInput > newPriceState.maxPriceInput) {
        newPriceState.minPriceInput = newPriceState.maxPriceInput;
        newPriceState.minPriceInputUSD = toCurrency(
          newPriceState.maxPriceInput,
          "USD",
        );
      }
    } else {
      if (newPriceState.maxPriceInput > dayPriceRange.totalMax) {
        newPriceState.maxPriceInput = dayPriceRange.totalMax;
        newPriceState.maxPriceInputUSD = toCurrency(
          dayPriceRange.totalMax,
          "USD",
        );
      } else if (newPriceState.maxPriceInput < newPriceState.minPriceInput) {
        newPriceState.maxPriceInput = newPriceState.minPriceInput;
        newPriceState.maxPriceInputUSD = toCurrency(
          newPriceState.minPriceInput,
          "USD",
        );
      }
    }

    this.setState(newPriceState);

    setSearchPrice({
      min: newPriceState.minPriceInput,
      max: newPriceState.maxPriceInput,
    });
  }

  handlePeriodToggle = () => {
    const { month, setSearchPrice } = this.props;

    const newPriceState = {
      minPriceInput: month ? dayPriceRange.totalMin : monthPriceRange.totalMin,
      maxPriceInput: month ? dayPriceRange.totalMax : monthPriceRange.totalMax,
      minPriceInputUSD: toCurrency(
        month ? dayPriceRange.totalMin : monthPriceRange.totalMin,
        "USD",
      ),
      maxPriceInputUSD: toCurrency(
        month ? dayPriceRange.totalMax : monthPriceRange.totalMax,
        "USD",
      ),
    };

    this.setState(newPriceState);

    setSearchPrice({
      min: newPriceState.minPriceInput,
      max: newPriceState.maxPriceInput,
    });
  };
}

export default withNamespaces("searchForm")(PriceFilter);

PriceFilter.propTypes = {
  toggleSearchPeriod: PropTypes.func,
  setSearchPrice: PropTypes.func,
  month: PropTypes.bool,
  price: PropTypes.object,
  open: PropTypes.bool,
  showOrHide: PropTypes.func,
};
